import React, { useState } from 'react';
import { connect } from 'react-redux';
import { startSubmit } from 'redux-form';
import { ToastContainer, Slide } from 'react-toastify';
import PropTypes, { shape, string, array } from 'prop-types';
import { Route, Redirect, Switch, withRouter, matchPath } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { some } from 'lodash';
import styled from 'styled-components';
import LifewayImpersonationBanner from 'impersonation-banner';
import * as LazyLoad from '../utils/LazyLoad';
import * as FullStory from '../utils/fullstory/fullstory';
import { saveToLocalStorage } from '../utils/locationHelper';
import css from '../../../css/css-variables';

import {
  PATH_PROFILE,
  PATH_USER_ADDRESS_MANAGEMENT,
  PATH_GIFT_CARDS,
  PATH_ORG_MGMT,
  PATH_ACCS,
  PATH_MANAGE_ACCESS,
  PATH_ORDER_HISTORY,
  PATH_SUBSCRIPTIONS,
  PATH_VIEW_GIFT_CARD,
  PATH_REDEEM_CODE,
  PATH_PDF_VIEW,
  PATH_ACCOUNT_CREATION,
  PATH_LOGGED_OUT,
  PATH_DIGITAL_MEDIA,
  PATH_MEDIA_CONTENT,
  ENABLE_ORG_MANAGEMENT,
  ORG_PATHS,
  INV_PATHS,
  PATH_AUTOSHIP,
  PATH_PAYMENT_METHODS,
  ENABLE_INTERCOM,
  PATH_DASHBOARD,
  ENABLE_RQ_SUBSCRIPTIONS,
  ENABLE_FS_USER_PROPERTIES,
  ENABLE_ACCOUNT_CREATION
} from '../constants';
import {
  onResize,
  setPageTitles,
  toggleModal,
  clearBannerState,
  tableOnInitColumns,
  tableUpdateSettings,
  getGiftCardBalancePending,
  emailGiftCardPending,
  pinManagementRequest,
  fetchUserDataPending,
  getSubscriptionsPending,
  getBillingAccsPending,
  getPersonalAutoshipsPending,
  toggleNavPanel as toggleNavPanelAction,
  getVODDevicesPending,
  getWebAuthnPublicKeysPending,
  submitNewsletterFormPending,
  getContentfulLinksPending,
} from '../actions/AppActions';
import Header from '../components/header/Header';
import Content from '../components/Content';
import SideBar from '../components/SideBar';
import MatchWhenAuthed from '../components/MatchWhenAuthed';
import { getInvitation } from '../selectors/PersonProfileSelectors';
import IntercomManager, { shutdownIntercom } from '../components/IntercomManager';
import { Footer } from '../components/footer/Footer';
import AccountCreationHeader from '../components/header/AccountCreationHeader.jsx';

const MainWrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only print {
    overflow: visible;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  width: 100%;

  @media only print {
    overflow: visible;
  }
`;

const HiddenPortal = styled.div`
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 2000;
`;

export const ToastWrapper = styled(ToastContainer)`
  width: auto !important;
  background-color: none !important;
  & div {
    color: ${css.colors.ButtonBlue} !important;
    padding: 12px;
  }

  & button {
    color: #${css.colors.ButtonBlue} !important;
  }



  .Toastify__toast--success {
    background: #C7EBC8;
    color: #2E7F34;
    border-radius: 8px;
    width: max-content;
    z-index: 300;
    min-height: fit-content;
    font-weight: 800;

    & div {
      padding: 0;
    }

    @media(max-width: 480px) {
      width: 100vw;
      text-align: center;
      margin-bottom: 0 !important;
    }

    svg {
      color: #0076C0;
    }
  }

  .Toastify__toast--warning {
    background: #D9EDF7;
    color: #264888;
    border-radius: 8px;
    width: max-content;
    z-index: 300;
    min-height: fit-content;
    font-weight: 800;

    & div {
      padding: 0;
    }

    @media(max-width: 480px) {
      width: 100vw;
      text-align: center;
      margin-bottom: 0 !important;
    }
    
    svg {
      color: #264888;
    }
  }

  .Toastify__toast--error {
    background: #FFC6C5;
    color: #A5120F;
    border-radius: 8px;
    z-index: 300;
    min-height: fit-content;
    font-weight: 800;

    & div {
      padding: 0;
    }

    @media(max-width: 480px) {
      width: 100vw;
      text-align: center;
      margin-bottom: 0 !important;
    }

    svg {
      color: #A5120F;
    }
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 1rem;
    }
  }

  .Toastify__toast-container--bottom-center {
    width: auto;
  }
`;

class Wrapper extends React.Component {

  componentDidMount() {
    const { resize, fetchUserData, getContentfulLinks, getVODDevices, getSubscriptions, getPersonalAutoships, getBillingAccs, isLoggedIn, location, getWebAuthnPublicKeys } = this.props;
    saveToLocalStorage(location);

    if (isLoggedIn) {
      getContentfulLinks();
      fetchUserData();
      getSubscriptions();
      getPersonalAutoships();
      getBillingAccs();
      getVODDevices();
      getWebAuthnPublicKeys();
    }

    //This code fires a onResize event that can be tapped into anywhere in the app
    if (!location.pathname.includes(PATH_ACCOUNT_CREATION)) {
    let isResizing;
    const onAppResize = debounce(evt => resize(evt), 66);

    ['resize', 'orientationchange'].forEach(eventName => window.addEventListener(eventName, evt => {
      if (!isResizing) {
        isResizing = window.requestAnimationFrame(() => {
          isResizing = 0;
          onAppResize(evt);
        });
      }
    }));
  }
}

  componentDidUpdate(prevProps) {
    const { location, onClearBanner, currentUser, linkedOrganizations } = this.props;

    // Remove any banner messages when changing locations
    if (location.pathname !== prevProps.location.pathname) {
      saveToLocalStorage(location);
      onClearBanner();
    }

    if(currentUser?.id && linkedOrganizations?.length > 0) {
      if (ENABLE_FS_USER_PROPERTIES) {
        try {
          FullStory.setUserProperties(currentUser?.id, { orgIds: linkedOrganizations.map(org => org?.organizationId) });
        } catch (error) {
          console.error('[FULLSTORY]', 'Failed to setup Fullstory user', { error });
        }
    }
  }
}

  onSetPageTitles = menu =>  {
    const { onPageTitles, pageTitles } = this.props;
    if (!pageTitles.size) onPageTitles(menu);
  }

  render() {
    const {
      location,
      breakPoints,
      isLoggedIn,
      onLogOut: parentOnLogOut,
      openedNavPanel,
      toggleNavPanel,
      modal,
      onToggleModal,
      referredBy,
      currentUser,
      selectedProfile,
      isImpersonationSession,
      invitation,
      breadCrumbs,
      submitNewsLetterForm,
      contentfulLinks,
      linkedOrganizations, 
      linkedOrgsPending
    } = this.props;

    const { pathname } = location;
    const match = matchPath(location.pathname, {
      path: '/organization/:organizationId',
    });

    const orgId = match?.params?.organizationId;

    const hideNavPaths = [PATH_VIEW_GIFT_CARD, PATH_PDF_VIEW, PATH_ACCOUNT_CREATION];
    const showHeader = pathsNotMatch([...hideNavPaths], pathname);
    const showAccountHeader = pathname.includes(PATH_ACCOUNT_CREATION);
    const showFooter = pathsNotMatch([PATH_ACCOUNT_CREATION], pathname);
    const showSideNav = pathsNotMatch(['/logged-out', ...hideNavPaths], pathname);
    const showTopBanner = (pathsNotMatch(['/logged-out', '/redirect', PATH_ORG_MGMT, PATH_ACCS, PATH_PDF_VIEW, PATH_VIEW_GIFT_CARD], pathname) && currentUser.firstName);
    const isInvitePage = pathname.includes('invite');
    const backgroundColor = showAccountHeader && '#F5F5F5';


    const onLogOut = () => {
    if(ENABLE_INTERCOM){
      shutdownIntercom();
    }
      parentOnLogOut();
    };

    return (
      // id is required for react portal //
      <>
      <MainWrapper id="main-wrapper">
        {isImpersonationSession && <LifewayImpersonationBanner email={currentUser?.emailAddress} logOut={onLogOut} />}
        {/* Top Nav*/}
        { showHeader &&
          <Header
            breakPoints={breakPoints}
            isLoggedIn={isLoggedIn}
            location={location}
            onLogOut={onLogOut}
            openedNavPanel={openedNavPanel}
            toggleNavPanel={toggleNavPanel}
            referredBy={referredBy}
            customer={currentUser}
            breadCrumbs={breadCrumbs}
            orgId={orgId}
          />
        }
        { showAccountHeader &&
          <AccountCreationHeader />
        }
        <div data-qa-hook="toast-container">
          <ToastWrapper
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            transition={Slide}
          />
        </div>
        <ContentWrapper>
          {/* Side Nav*/}
          <div style={{ display: 'flex' }}>
          {showSideNav &&
            <SideBar
              location={location}
              breakPoints={breakPoints}
              processMenuTree={this.onSetPageTitles}
              referredBy={referredBy}
              isLoggedIn={isLoggedIn}
              orgId={orgId}
              onLogOut={onLogOut}
              linkedOrganizations={linkedOrganizations}
              linkedOrgsPending={linkedOrgsPending}
            />
          }

          <Content backgroundColor={backgroundColor} currentUser={currentUser}>
            <HiddenPortal id="main-portal-container" />
            <Switch>
              <MatchWhenAuthed
                path={PATH_DASHBOARD}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.AccountHome />}
              />
              <MatchWhenAuthed
                path={PATH_PROFILE}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.Profile />}
              />
              <MatchWhenAuthed
                path={PATH_USER_ADDRESS_MANAGEMENT}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.ShippingAddresses />}
              />
              <MatchWhenAuthed
                path={PATH_GIFT_CARDS}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.GiftCards location={location} />}
              />
              <MatchWhenAuthed
                path={PATH_PAYMENT_METHODS}
                isLoggedIn={isLoggedIn}
                component={(props) => <LazyLoad.PaymentMethods location={location} {...props} />}
              />
              {ENABLE_ORG_MANAGEMENT && <MatchWhenAuthed
                path={INV_PATHS.ORG_INVITE}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.InvitationPage />}
              />}
              {ENABLE_ORG_MANAGEMENT && <MatchWhenAuthed
                path={INV_PATHS.LICENSE_INVITE}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.InvitationPage />}
              />}
              {ENABLE_ORG_MANAGEMENT && <MatchWhenAuthed
                path={ORG_PATHS.BASE}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.OrgPages />}
              />}
              {!ENABLE_ORG_MANAGEMENT && <MatchWhenAuthed
                path={PATH_ORG_MGMT}
                isLoggedIn={isLoggedIn}
                component={(props) => <LazyLoad.OrgMgmt location={location} {...props} />}
              />}
              <MatchWhenAuthed
                exactly
                path={`${PATH_MANAGE_ACCESS}/:mediaType/:licenseId`}
                isLoggedIn={isLoggedIn}
                component={props => <LazyLoad.ManageAccess location={location} {...props} />}
              />
              <MatchWhenAuthed
                exactly
                path={`${PATH_MANAGE_ACCESS}/:mediaType`}
                isLoggedIn={isLoggedIn}
                component={props => <LazyLoad.ManageAccess location={location} {...props} />}
              />
              <MatchWhenAuthed
                path={PATH_MANAGE_ACCESS}
                isLoggedIn={isLoggedIn}
                component={props => <LazyLoad.ManageAccess location={location} {...props} />}
              />
              <MatchWhenAuthed
                exactly
                path={`${PATH_ORDER_HISTORY}/order/:id`}
                isLoggedIn={isLoggedIn}
                component={props => <LazyLoad.OrderDetails location={location} {...props} />}
              />
              <MatchWhenAuthed
                exactly
                path={[`${PATH_ORDER_HISTORY}(/[^go]*)?`, PATH_AUTOSHIP]}
                isLoggedIn={isLoggedIn}
                component={props => <LazyLoad.OrdersAndAutoship location={location} {...props.params} />}
              />
              <MatchWhenAuthed
                path={PATH_DIGITAL_MEDIA}
                isLoggedIn={isLoggedIn}
                component={() => <LazyLoad.DigitalMedia location={location} />}
              />
              <MatchWhenAuthed
                path={PATH_SUBSCRIPTIONS}
                isLoggedIn={isLoggedIn}
                component={() => ENABLE_RQ_SUBSCRIPTIONS ? <LazyLoad.SubscriptionsContainer /> : <LazyLoad.Subscriptions />}
              />

              <Route path={PATH_REDEEM_CODE} exact render={() => <LazyLoad.RedeemCode isLoggedIn={isLoggedIn} currentUser={currentUser} />} />
              <Route path={PATH_VIEW_GIFT_CARD} exact render={() => <LazyLoad.GiftCards isLoggedIn={isLoggedIn} location={location} />} />
              <Route path={PATH_PDF_VIEW} component={() => <LazyLoad.PdfView location={location} />} />
              {ENABLE_ACCOUNT_CREATION &&
                <Route path={PATH_ACCOUNT_CREATION} component={() => <LazyLoad.AccountCreation />} />
              }
              <Route path={PATH_LOGGED_OUT} render={() => <LazyLoad.LoggedOut isLoggedIn={isLoggedIn} location={location} onLogOut={onLogOut} />} />

              {/* Adding protective redirects from /media-content to /digital-media for legacy links */}
              <Redirect path={`${PATH_MEDIA_CONTENT}/:type`} to={`${PATH_DIGITAL_MEDIA}/:type`} />
              <Redirect exact path={PATH_MEDIA_CONTENT} to={PATH_DIGITAL_MEDIA} />
              <Redirect to={PATH_DASHBOARD} />
            </Switch>
          </Content>
          </div>
        {showFooter && (
          <Footer submitNewsLetter={submitNewsLetterForm} contentfulLinks={contentfulLinks} />
        )}
        </ContentWrapper>

        {/* Modal Cmpt */}
        {location.pathname.indexOf(PATH_LOGGED_OUT) < 0 &&
          <LazyLoad.ModalManager />
        }
        {ENABLE_INTERCOM && <IntercomManager />}
      </MainWrapper>
      </>
    );
  }
}

function pathsNotMatch(paths, pathname) {
  return !(some(paths, path => path === pathname));
}

function mapStateToProps(state) {
  return {
    linkedOrganizations: state.app.getIn(['currentUser', 'linkedOrgs']).toJS(),
    contentfulLinks: state.app.getIn(['contentfulLinks']).toJS(),
    linkedOrgsPending: state.app.getIn(['currentUser', 'linkedOrgsLoading']),
    breakPoints: state.app.get('breakPoints'),
    referredBy: state.app.get('referredBy'),
    openedNavPanel: state.app.getIn(['nav', 'openPanel']),
    pageTitles: state.app.get('pageTitles'),
    isLoggedIn: state.app.get('isLoggedIn'),
    isImpersonationSession: state.app.get('isImpersonationSession'),
    banner: state.app.get('banner').toJS(),
    modal: state.app.get('modal').toJS(),
    intl: state.intl.messages,
    processedItem: state.app.get('processingItem'),
    purchases: state.app.get('giftCardPurchases').toJS(),
    currentGiftCard: (state.app.get('currentGiftCard') || { toJS: () => {} }).toJS(),
    currentUser: state.app.get('currentUser').toJS(),
    selectedProfile: state.app.get('selectedProfile').toJS(),
    multipleDownloadsTableSettings: (state.app.getIn(['tableSettings', 'multipleDownloads']) || { toJS: () => {} }).toJS(),
    multipleDownloadsTable: (state.app.getIn(['digitalMedia', 'multipleDownloads']) || { toJS: () => {} }).toJS(),
    invitation: getInvitation(state)?.data,
    breadCrumbs: (state.app.get('breadCrumbs'))?.toJS() || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleNavPanel: (name, open) => dispatch(toggleNavPanelAction(name, open)),
    resize: () => dispatch(onResize()),
    onPageTitles: payload => dispatch(setPageTitles(payload)),
    onToggleModal: () => dispatch(toggleModal()),
    onClearBanner: () => dispatch(clearBannerState()),
    onTableOnInitColumns: payload => dispatch(tableOnInitColumns(payload)),
    updateTableSettings: payload => dispatch(tableUpdateSettings(payload)),
    checkBalance: payload => dispatch(getGiftCardBalancePending(payload)),
    emailGiftCard: payload => {
      dispatch(startSubmit('emailGiftCard'));
      dispatch(emailGiftCardPending(payload));
    },
    onPinFormSubmit: payload => dispatch(pinManagementRequest(payload)),
    fetchUserData: () => dispatch(fetchUserDataPending()),
    getContentfulLinks: () => dispatch(getContentfulLinksPending()),
    getSubscriptions: () => dispatch(getSubscriptionsPending()),
    getPersonalAutoships: () => dispatch(getPersonalAutoshipsPending()),
    getBillingAccs: () => dispatch(getBillingAccsPending()),
    getVODDevices: () => dispatch(getVODDevicesPending()),
    getWebAuthnPublicKeys: () => dispatch(getWebAuthnPublicKeysPending()),
    submitNewsLetterForm: (values, actions) => dispatch(submitNewsletterFormPending({ values, actions }))
  };
}

const { func, bool, object, arrayOf } = PropTypes;

Wrapper.propTypes = {
  linkedOrganizations: array,
  contentfulLinks: object,
  linkedOrgsPending: string,
  breakPoints: object.isRequired,
  isOpen: bool.isRequired,
  toggleNavPanel: func.isRequired,
  location: object.isRequired,
  isLoggedIn: bool.isRequired,
  isImpersonationSession: bool,
  onLogOut: func,
  resize: func.isRequired,
  pageTitles: object,
  onPageTitles: func,
  modal: object,
  onToggleModal: func,
  onClearBanner: func,
  multipleDownloadsTableSettings: object,
  onTableOnInitColumns: func,
  multipleDownloadsTable: object,
  updateTableSettings: func,
  onPinFormSubmit: func,
  currentUser: object,
  selectedProfile: object,
  fetchUserData: func,
  getContentfulLinks: func,
  getSubscriptions: func,
  getPersonalAutoships: func.isRequired,
  getBillingAccs: func,
  getVODDevices: func,
  getWebAuthnPublicKeys: func,
  invitation: shape({
    meta: shape({
      organizationName: string.isRequired
    })
  }),
  breadCrumbs: arrayOf(object),
};

//exported for testing ConnectedWrapper //
const ConnectedWrapper = connect(mapStateToProps, mapDispatchToProps)(Wrapper);
export { ConnectedWrapper, Wrapper };

export default withRouter(ConnectedWrapper);
