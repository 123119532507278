import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  browserName,
  browserVersion,
  isIE,
  isSafari,
} from 'react-device-detect';
import styled from 'styled-components';
import LogoIcon from '../../../../img/sprite/lifeway-logo.svg';
import AlertIcon from '../../../../img/sprite/alert-circle.svg';
import ReturnArrow from '../../../../img/sprite/return-arrow.svg';
import { LIFEWAY_COM_URL, PATH_PROFILE } from '../../constants';
import css from '../../../../css/css-variables';

const LogoLink = styled.a`
  flex: 0 0 auto;

  width: 12.875em;

  @media (max-width: 720px) {
    width: 6.375em;
    display: inline-block;
  }
`;

const Header = styled.header`
  border-bottom: 1px solid ${css.colors.BoxShadowGray};
  background: ${css.colors.lifewayWhite};
  flex: 0 ${css.header.Height};
  height: ${css.header.Height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  background-color: ${css.colors.lifewayWhite};
  padding: 1rem 4rem;

  font-family: sofia-pro, 'helvetica neue', helvetica, arial, sans-serif;

  @media (max-width: 720px) {
    width: 100%;
    flex: none;
    border-bottom-width: 0px;
    height: auto;
    padding-bottom: 0.438em;
    z-index: 1;
    align-self: flex-start;
  }
`;

const Svg = styled.svg`
  height: 4.688em;
  width: 12.875em;
  background-color: transparent;

  @media (max-width: 720px) {
    width: 100%;
    height: 1.75em;
  }
`;

const Title = styled.h1`
  color: ${css.colors.DarkGray};
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem;
  letter-spacing: 0rem;

  @media (max-width: 720px) {
    font-size: ${css.fontSize.medium};
  }
`;

const ReturnLink = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const StyledAnchor = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: ${css.colors.Progress};
  text-align: right;

  font-size: ${css.fontSize.small};
  font-style: normal;
  font-weight: 400;

  p {
    padding-bottom: 2px;
  }
`;

const AlertSvg = styled.svg`
  position: relative;
  bottom: 0.1em;
  right: 0.2em;
`;

const Banner = styled.div`
  padding: 0.5em;
  background-color: #f3dddf;
  color: #b54a42;
  text-align: center;
`;

const HeaderWrapper = styled.div`
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

const UnsupportedBrowserMessage = () => {
  return (
    <Banner>
      <AlertSvg>
        <use xlinkHref={`#${AlertIcon.id}`} />
      </AlertSvg>
      <strong>
        {browserName} {browserVersion}
      </strong>{' '}
      is not a supported browser. Please use a{' '}
      <a
        href="https://browsehappy.com"
        target="_blank"
        without
        rel="noreferrer"
      >
        recommended browser
      </a>{' '}
      to improve your overall experience and security.
    </Banner>
  );
};

const AccountCreationHeader = () => {
  const isUnsupportedBrowser = isIE || (isSafari && browserVersion < 11);
  const history = useHistory();
  const referrer = document.referrer;

  const handleReturn = () => {
    const currentDomain = window.location.origin || LIFEWAY_COM_URL;

    if (referrer.startsWith(currentDomain)) {
      history.goBack();
    } else {
      history.push(LIFEWAY_COM_URL);
    }
  };

  return (
    <HeaderWrapper
      className="header"
      data-qa-hook="account-creation-header"
      data-tracking-section="header"
    >
      {isUnsupportedBrowser && <UnsupportedBrowserMessage />}
      <Header>
        <LogoLink
          href={LIFEWAY_COM_URL}
          data-qa-hook="myLifeway.logo"
          data-tracking-component="logo"
          data-tracking-id="lifeway"
        >
          <Svg>
            <use xlinkHref={`#${LogoIcon.id}`} />
          </Svg>
        </LogoLink>

        <Title>Create Church Account</Title>

        <ReturnLink
          onClick={handleReturn}
          data-tracking-component="button"
          data-tracking-id="return"
        >
          <StyledAnchor href={PATH_PROFILE}>
            <svg>
              <use xlinkHref={`#${ReturnArrow.id}`} />
            </svg>
            <p>return</p>
          </StyledAnchor>
        </ReturnLink>
      </Header>
    </HeaderWrapper>
  );
};

export default AccountCreationHeader;
